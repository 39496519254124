import type { MachineryAccessoryCategory, MachineryRubrics } from '~/types'

const MachineryRubricWiseAccessoryPricing: Record<
  MachineryRubrics,
  Partial<Record<MachineryAccessoryCategory, number>> | undefined
> = {
  crane: { forkAdjuster: 180 },

  // Not yet defined
  electricForklift: undefined,
  dieselForklift: undefined,
  gasForklift: undefined,
  allTerrainForklift: undefined,
  telescopicForklift: undefined,
  rotatingForklift: undefined,
  sideLoader: undefined,
  fourWayForklift: undefined,
  scissorWorkPlatform: undefined,
  articulatedTelescopicWorkPlatform: undefined,
  telescopicWorkPlatform: undefined,
  verticalMastPlatform: undefined,
  reachTruck: undefined,
  constructionMachinery: undefined,
  tugger: undefined,
  warehouseTechnology: undefined,
  trailer: undefined,
  tractor: undefined,
  selfPropelledVerticalWorkPlatform: undefined,
  truckWorkPlatform: undefined,
  railPlatform: undefined,
}

export default MachineryRubricWiseAccessoryPricing
