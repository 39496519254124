import type { MachineryAccessoryCategory } from '~/types'

export default {
  workBasket: 25,
  clipOnHook: 15,
  clipOnBasket: 15,
  fork: 5,
  forkCarriage: 0,
  latticeMastWinch: 25,
  hookShort: 15,
  hookLong: 15,
  jib: 120,
  craneArm: 15,
  forkExtension: 5,
  shovel: 15,
  forkAdjuster: 0,
  winch: 25,
  sideShifter: 0,
  rotaryDevice: 15,

  // Not yet defined
  wheels: undefined,
  charger: undefined,
  weights: undefined,
  rollers: undefined,
  stands: undefined,
  ramps: undefined,
  heavyCasters: undefined,
  loadDistributionPlates: undefined,
  ballast: undefined,
  coilDorn: undefined,
  abusBogie: undefined,
  miscellaneous: undefined,
  heavyDutyLifter: undefined,
  basePlate: undefined,
  trailer: undefined,
  storageSpace: undefined,
  internalOperatingEquipment: undefined,
} satisfies Record<MachineryAccessoryCategory, number | undefined>
