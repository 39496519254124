import type { MachineryAccessoryCategory, MachineryRubrics } from '~/types'

export default function (machineryAccessoryCategory: MachineryAccessoryCategory, machineryRubric?: MachineryRubrics) {
  if (!machineryRubric) {
    return getShopMachineryAccessoryCategoryPrice[machineryAccessoryCategory]
  }

  const baseAccessoryPrice = getShopMachineryAccessoryCategoryPrice[machineryAccessoryCategory]
  const machineryRubricBasedAccessoryPricing = getShopMachineryAccessoryCategoryPriceByRubric[machineryRubric]
  const rubricBasedAccessoryPrice = machineryRubricBasedAccessoryPricing
    ? machineryRubricBasedAccessoryPricing[machineryAccessoryCategory]
    : undefined
  return rubricBasedAccessoryPrice ?? baseAccessoryPrice
}
